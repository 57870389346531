<template>
  <div class="NewsroomEditCategories">
    <v-button
      class="is-outlined is-grey is-full-width"
      data-testid="NewsroomEditCategoriesTrigger"
      @click="startEditing"
    >
      {{ $t('pages.newsroom_edit.set_categories') }}
    </v-button>
    <template v-if="initialized">
      <el-dialog
        :visible.sync="isEditing"
        :title="$t('components.story_newsroom_edit.newsroom_edit_categories.title')"
        :before-close="beforeModalClose"
        data-testid="NewsroomEditCategoriesDialog"
        class="is-fullscreen-mobile"
        width="600px"
      >
        <div class="m-b-l is-size-6">
          {{ $t('components.story_newsroom_edit.newsroom_edit_categories.body') }}
        </div>

        <categories-picker
          v-model="cached_value"
          :categories="categories"
          :categories-flat="categoriesFlat"
        />
        <template slot="footer">
          <v-button
            :loading="loading"
            class="is-primary"
            data-testid="NewsroomEditCategories__Save"
            @click="save"
          >
            {{ $t('forms.done') }}
          </v-button>
        </template>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as CategoryService from '@hypefactors/shared/js/services/api/CategoryService'
import CategoriesPicker from '@hypefactors/shared/js/components/forms/CategoriesPicker.vue'

export default {
  name: 'NewsroomEditCategories',

  components: {
    CategoriesPicker
  },

  props: {
    value: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isEditing: false,
      initialized: false,
      categories: [],
      cached_value: []
    }
  },

  computed: {
    ...mapGetters({ categoriesFlat: 'allStoryCategories' })
  },

  async mounted () {
    this.categories = await CategoryService.getAll()

    this.initialized = true
  },

  methods: {
    startEditing () {
      this.cached_value = this.value.slice(0)
      this.isEditing = true
    },

    stopEditing () {
      this.isEditing = false
    },

    save () {
      this.$emit('input', this.cached_value)
    },

    cancel () {
      this.stopEditing()
      this.$emit('cancel')
    },

    async beforeModalClose (next) {
      if (JSON.stringify(this.cached_value) !== JSON.stringify(this.value)) {
        try {
          await this.$confirm(this.$t('components.story_newsroom_edit.newsroom_edit_categories.confirm_close_without_save'))
        } catch (err) {
          return
        }
      }
      next()
    }

  }
}
</script>
