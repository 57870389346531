<template>
  <div
    class="NewsroomEditCover"
    data-testid="NewsroomEditCover"
  >
    <croppie-wrapper
      ref="page_header"
      v-model="currentValue"
      :settings="croppieSettings"
      :brand-id="newsroom.brand.id"
      :no-image-color="newsroom.main_color"
      :crop-options="croppieSettings.cropOptions"
      identifier="cover"
      entity="NewsroomsPageHeader"
      @removed="triggerInput"
      @save="save"
    >
      <span>{{ $t('components.story_newsroom_edit.newsroom_edit_cover.add') }}</span>
    </croppie-wrapper>
  </div>
</template>

<script>
import { debounceMixin } from '@hypefactors/shared/js/mixins/debounceMixin'
import { generateUniqueWatcher } from '@hypefactors/shared/js/utils/componentUtilities'
import _cloneDeep from 'lodash/cloneDeep'
import { croppieDataFactory } from '@hypefactors/shared/js/factories/croppie'
import { round } from '@hypefactors/shared/js/utils/numberUtils'

export default {
  name: 'NewsroomEditCover',

  mixins: [debounceMixin(['resize']), 150],

  props: {
    value: {
      type: Object,
      required: true
    },
    newsroom: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      currentValue: croppieDataFactory(),

      coverRatio: round(781 / 120),
      fullWidth: 781,
      fullHeight: 120
    }
  },

  computed: {
    croppieSettings () {
      return {
        viewport: { width: this.fullWidth, height: this.fullHeight },
        boundary: { width: this.fullWidth, height: this.fullHeight },
        cropOptions: {
          // we set original so the image keeps its size, just uses the boundary aspect to get cropped
          size: 'original'
        }
      }
    }
  },

  watch: {
    value: {
      immediate: true,
      handler: generateUniqueWatcher('syncValue')
    }
  },

  mounted () {
    this.resize()
    window.addEventListener('resize', this.resize)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.resize)
  },

  methods: {
    async resize () {
      this.fullWidth = document.body.clientWidth
      this.fullHeight = round(this.fullWidth / this.coverRatio)
      await this.$nextTick()
      if (this.$refs.page_header) {
        this.$refs.page_header.bind()
      }
    },

    syncValue () {
      this.currentValue = _cloneDeep(this.value)
    },

    async save () {
      try {
        await this.$refs.page_header.uploadImage()
        this.triggerInput()
      } catch (err) {
        if (this.$api.isCancelToken(err)) return
        this.$displayRequestError(err, this.$t('errors.could_not_upload_cover'))
      }
    },

    triggerInput () {
      this.$emit('input', this.currentValue)
    }
  }
}
</script>

<style>
.NewsroomEditCover .CroppieWrapper {
  display: block;
}
</style>
