<template>
  <div class="NewsroomEditSocialLinks">
    <newsroom-edit-social-links-data-provider
      #default="{ groupedHandles, handleRemove, handleCreate, handleUpdate }"
      :newsroom-id="newsroomId"
    >
      <div class="social-links">
        <newsroom-edit-social-links-dropdown
          v-for="(handlesGroup, socialNetwork) in groupedHandles"
          :key="socialNetwork"
          :type="socialNetwork"
          :handles="handlesGroup"
          :newsroom-id="newsroomId"
          @removed="handleRemove"
          @created="handleCreate"
          @updated="handleUpdate"
        />
      </div>
    </newsroom-edit-social-links-data-provider>
  </div>
</template>

<script>
import NewsroomEditSocialLinksDropdown from './NewsroomEditSocialLinksDropdown'
import NewsroomEditSocialLinksDataProvider from './NewsroomEditSocialLinksDataProvider'

export default {
  name: 'NewsroomEditSocialLinks',

  components: {
    NewsroomEditSocialLinksDataProvider,
    NewsroomEditSocialLinksDropdown
  },

  props: {
    newsroomId: {
      type: String,
      required: true
    }
  }
}
</script>
