<template>
  <div class="NewsroomEditMainColor">
    <div v-if="!value && !isEditing">
      <v-button
        class="is-outlined is-grey is-full-width"
        @click="startEditing"
      >
        {{ $t('pages.newsroom_edit.add_newsroom_colors') }}
      </v-button>
    </div>

    <template v-else>
      <color-picker
        ref="field"
        :value="value || defaultColor"
        :label="$t('components.newsroom_section_colors.main_color_label')"
        has-input
        @input="save"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'NewsroomEditMainColor',

  props: {
    value: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isEditing: false,
      defaultColor: '#d3d3d3'
    }
  },

  methods: {
    startEditing () {
      this.isEditing = true
    },

    stopEditing () {
      this.isEditing = false
    },

    save (value) {
      this.$emit('input', value)
    }
  }
}
</script>
