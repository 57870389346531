<template>
  <div class="NewsroomEditLocalisation">
    <v-button
      class="is-outlined is-grey is-full-width"
      data-testid="NewsroomEditLocalisationTrigger"
      @click="startEditing"
    >
      <i class="hf hf-gear" />
      <span class="m-l-s">
        {{ $t('components.story_newsroom_edit.newsroom_edit_localisation.button') }}
      </span>
    </v-button>
    <el-dialog
      :visible.sync="isEditing"
      :title="$t('components.story_newsroom_edit.newsroom_edit_localisation.title')"
      data-testid="NewsroomEditLocalisationDialog"
      class="is-fullscreen-mobile"
      @close="stopEditing"
    >
      <form-field
        :label="$t('forms.country')"
        :tooltip="$t('components.story_newsroom_edit.newsroom_edit_localisation.tooltip_country')"
        :validator="validator && validator.countries"
        class="m-b-l"
      >
        <country-picker
          v-model="form.country"
          value-key="id"
          data-testid="NewsroomEditLocalisation_country"
          :exclude="takenCountries"
        />
      </form-field>

      <form-field
        :label="$t('forms.legal_warning')"
        :tooltip="$t('components.story_newsroom_edit.newsroom_edit_localisation.tooltip_legal_warning')"
        :validator="validator && validator.countries"
        class="m-b-l"
      >
        <el-checkbox v-model="form.legal_warning" class="m-l-l">
          {{ $t('forms.legal_warning_box') }}
        </el-checkbox>
      </form-field>

      <div class="columns">
        <div class="column is-6">
          <form-field :label="$t('components.story_newsroom_edit.newsroom_edit_localisation.publish_settings')" required>
            <newsroom-toggle-publish-button
              ref="publishButton"
              :newsroom="newsroom"
              class="m-t-s"
              @change="$emit('published', $event)"
            />
          </form-field>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <v-button
          class="is-plain is-dark"
          @click="stopEditing"
        >
          {{ $t('forms.cancel') }}
        </v-button>
        <v-button
          :loading="isLoading"
          class="is-primary"
          data-testid="NewsroomEditLocalisation_save"
          @click="onSave"
        >
          {{ $t('forms.save') }}
        </v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _clone from 'lodash/clone'
import NewsroomTogglePublishButton from './NewsroomTogglePublishButton'
import { NewsroomService } from '@hypefactors/shared/js/services/api/NewsroomService'
import { Newsroom } from '@hypefactors/shared/js/models/Newsroom'

export default {
  components: { NewsroomTogglePublishButton },

  props: {
    value: {
      type: Object,
      default: () => ({ country: [] })
    },
    validator: {
      type: Object,
      default: null
    },
    newsroom: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      form: {
        country: null,
        legal_warning: false
      },
      newsrooms: [],
      isEditing: false
    }
  },

  computed: {
    takenCountries () {
      return this.newsrooms.map(n => n.country)
    }
  },

  async created () {
    await this.fetchNewsroomSiblings()
  },

  methods: {
    stopEditing () {
      this.isEditing = false
    },

    onSave () {
      this.$emit('input', {
        country: this.form.country,
        legal_warning: this.form.legal_warning
      })
    },

    startEditing () {
      // clone the prop locally
      this.form = _clone({
        country: this.value.country,
        legal_warning: this.newsroom.legal_warning
      })
      this.isEditing = true
    },

    async fetchNewsroomSiblings () {
      try {
        let response = await NewsroomService.getNewsroomSiblings(this.newsroom.id, { params: { include: 'country' } })

        this.newsrooms = response.map(n => new Newsroom(n))
      } catch (err) {
        this.$displayRequestError(err)
      }
    }
  }
}
</script>
