<script>
import EditableField from '@hypefactors/shared/js/components/forms/controls/EditableField'

import EditableControlContactInfo from '@/components/newsroomEdit/controls/EditableControlContactInfo.vue'

/**
 * Extends the {@see module:EditableField} to register control components
 * @module EditableFieldExtended
 */
export default {
  name: 'EditableFieldExtended',
  components: {
    EditableControlContactInfo
  },
  extends: EditableField
}
</script>
