import { required, maxLength, email, requiredIf } from 'vuelidate/lib/validators'

import Form from '@/services/forms/Form'

import { croppieDataFactory } from '@hypefactors/shared/js/factories/croppie'

import { isSimpleUrl } from '@hypefactors/shared/js/utils/validation'
import { NewsroomService } from '@hypefactors/shared/js/services/api/NewsroomService'
import CategoryService from '@hypefactors/shared/js/services/CategoryService'

class NewsroomForm extends Form {
  /** @type {import('axios').CancelTokenSource} */
  // cancelToken = null

  /**
   * Merges in the model into the form
   * @param {Newsroom} model
   */
  mergeModel (model) {
    return this.merge({
      ...model,
      categories: CategoryService.transformForEditing(model.categories)
    })
  }

  update (newsroomId) {
    this.cancelToken && this.cancelToken.cancel()
    this.cancelToken = this._api.cancelToken()
    this.setPayloadTransformer((payload) => ({
      ...payload,
      country: payload.country && payload.country.iso_alpha2,

      categories: CategoryService.transformForSaving(payload.categories),

      files: payload.files.map(f => f.id)
    }))
    return this.submit((payload) => NewsroomService.update(newsroomId, payload,
      {
        params: { include: ['brand', 'categories', 'contacts', 'country', 'files'] },
        cancelToken: this.cancelToken.token
      }
    ))
  }
}

export function createNewsroomForm () {
  return new NewsroomForm({
    logo: { value: croppieDataFactory() },
    page_header: { value: croppieDataFactory() },

    name: { value: null, rules: { required } },
    description: { value: null, rules: { maxLength: maxLength(1200) } },
    legal_warning: { value: false },

    website: { value: '', rules: { url: isSimpleUrl } },

    address: { value: null },
    country: { value: null, rules: { required } },

    categories: { value: [] },

    contact_name: {
      value: null,
      rules: {
        /* Required only if the other contact data is present */
        required: requiredIf(function () {
          return this.form.contact_role || this.form.contact_email || this.form.contact_phone
        })
      }
    },
    contact_role: { value: null },
    contact_email: { value: null, rules: { email } },
    contact_phone: { value: null },

    main_color: { value: null },
    secondary_color: { value: null },

    videos: { value: [] },
    files: { value: [] }
  })
}
