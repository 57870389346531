<template>
  <a
    :href="embedPageUrl"
    class="NewsroomEditGoToEmbedNewsroom button is-primary is-outlined"
    @click.prevent="goToEmbedPage"
  >
    {{ $t('components.story_newsroom_edit.newsroom_edit_localisation.go_to_embed_newsroom') }}
  </a>
</template>

<script>
/**
 * @module NewsroomEditGoToEmbedNewsroom
 */
export default {
  name: 'NewsroomEditGoToEmbedNewsroom',

  props: {
    newsroom: {
      type: Object,
      required: true
    }
  },

  computed: {
    embedUrl () {
      return this.$getUrl(`stories/newsroom-embed/${this.newsroom.id}?brand=${this.newsroom.brand.id}`, 'hypefactors')
    },
    /**
     * Returns a link to the EmbedNewsroom page on Hypefactors
     * @returns {string}
     */
    embedPageUrl () {
      return this.newsroom.is_published ? this.embedUrl : ''
    }
  },

  methods: {
    async goToEmbedPage () {
      try {
        if (!this.newsroom.is_published) {
          await this.$confirm(this.$t('components.dialog_newsroom_is_not_published.message'), {
            title: this.$t('components.dialog_newsroom_is_not_published.title'),
            confirmButtonText: this.$t('forms.publish'),
            cancelButtonText: this.$t('forms.cancel'),
            type: 'warning'
          })
          // TODO: Move this to Vuex so we dont have this garbage.
          return this.$bus.$emit('NewsroomTogglePublishButton:publish')
        }
        this.$redirectTo(this.embedUrl, 0, 'hf_newsroom_embed')
      } catch (err) {
        if (err === 'cancel') return
        this.$displayRequestError(err, this.$t('errors.cannot_publish_story'))
      }
    }
  }
}
</script>
