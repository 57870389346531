<template>
  <v-button
    v-if="newsroom.is_published"
    :loading="isLoading"
    class="is-primary NewsroomTogglePublishButton"
    @click="unpublish"
  >
    {{ $t('components.newsroom_toggle_publish_button.unpublish') }}
  </v-button>
  <v-button
    v-else
    :loading="isLoading"
    class="is-primary NewsroomTogglePublishButton"
    @click="publish"
  >
    {{ $t('components.newsroom_toggle_publish_button.publish') }}
  </v-button>
</template>

<script>
import { mapActions } from 'vuex'
import { NewsroomService } from '@hypefactors/shared/js/services/api/NewsroomService'

/**
 * Generates a button that toggles a newsroom's published status
 * @module NewsroomTogglePublishButton
 */
export default {
  name: 'NewsroomTogglePublishButton',

  props: {
    newsroom: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    isLoading: false
  }),

  mounted () {
    // TODO: This is retarded, now that the button is hidden...
    if (this.$route.query.auto_publish === 'true') {
      this.publishNewsroom()
    }
    this.$bus.$on('NewsroomTogglePublishButton:publish', this.publishNewsroom)
  },

  beforeDestroy () {
    this.$bus.$off('NewsroomTogglePublishButton:publish')
  },

  methods: {
    ...mapActions(['fetchUserBrands']),

    /**
     * Confirm before publishing
     * @returns {Promise<void>}
     */
    async publish () {
      if (this.newsroom.is_published) return
      try {
        await this.$confirm(this.$t('pages.newsroom_edit.publishing_newsroom_confirmation'))
        await this.publishNewsroom()
      } catch (err) {}
    },

    /**
     * Confirm before Unpublishing
     * @returns {Promise<void>}
     */
    async unpublish () {
      if (!this.newsroom.is_published) return
      try {
        await this.$confirm(this.$t('pages.newsroom_edit.unpublishing_newsroom_confirmation'))
        this.unpublishNewsroom()
      } catch (err) { }
    },

    /**
     * Publishes the newsroom
     * @returns {Promise<any>}
     */
    async publishNewsroom () {
      this.isLoading = true

      try {
        await NewsroomService.publish(this.newsroom.id, {}, {
          params: {
            include: ['categories', 'countries', 'files']
          }
        })
        await this.fetchUserBrands()
        this.$emit('change', true)

        this.$notify.success({
          title: this.$t('general.success'),
          message: this.$t('success.newsroom_published')
        })
      } catch (e) {
        this.$displayRequestError(e, this.$t('errors.newsroom_published'))
      }
      this.isLoading = false
    },

    /**
     * Unpublishes a newsroom
     * @returns {Promise<any>}
     */
    async unpublishNewsroom () {
      this.isLoading = true

      try {
        await NewsroomService.unpublish(this.newsroom.id, {}, {
          params: {
            include: ['categories', 'countries', 'files']
          }
        })
        await this.fetchUserBrands()
        this.$emit('change', false)

        this.$notify.success({
          title: this.$t('general.success'),
          message: this.$t('success.newsroom_unpublished')
        })
      } catch (error) {
        this.$displayRequestError(error, this.$t('errors.newsroom_unpublished'))
      }
      this.isLoading = false
    }
  }
}
</script>
