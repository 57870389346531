<template>
  <div v-if="newsroom" class="company-page">
    <!-- NEWSROOM COVER -->
    <newsroom-edit-cover
      ref="page_header"
      :value="form.page_header"
      :newsroom="newsroom"
      @input="patch({ page_header: $event })"
    />

    <div class="container">
      <div class="columns">
        <!-- NEWSROOM LOGO -->
        <div class="column is-one-quarter is-position-relative">
          <div class="company-page__logo-wrapper z-index-1">
            <newsroom-edit-logo
              ref="logo"
              :value="form.logo"
              :newsroom="newsroom"
              class="has-text-centered-mobile"
              @input="updateLogo"
            />
          </div>
        </div>

        <!-- NEWSROOM NAME -->
        <div
          class="column is-three-quarters"
          data-testid="newsroomEditableName"
        >
          <alternative-newsroom-picker
            :newsroom="newsroom"
            edit-mode
            class="m-b-m"
          />
          <div class="columns company-page__name is-aligned-middle">
            <div class="column">
              <editable-field-extended
                ref="name"
                :value="form.name"
                :validator="$v.form.name"
                :has-cancel-button="false"
                :label="$t('forms.newsroom_name')"
                :placeholder="$t('forms.newsroom_name')"
                floating-label
                inline-placeholder
                control-class="NewsroomEdit__ControlEditableName"
                @input="patch({ name: $event }, 'name')"
                @cancel="revertOldData"
              >
                <h1>
                  {{ form.name }}
                </h1>
              </editable-field-extended>
            </div>
            <div class="column is-narrow">
              <newsroom-edit-go-to-embed-newsroom
                v-if="newsroom.is_published"
                :newsroom="newsroom"
              />
              <newsroom-edit-preview-button
                :newsroom="newsroom"
                edit-mode
                class="button is-primary m-l-s"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-one-quarter">
          <div class="company-page-sidebar">
            <!-- SOCIAL LINKS -->
            <newsroom-edit-social-links :newsroom-id="newsroom.id" />

            <div class="company-info">
              <!-- DESCRIPTION -->
              <div
                class="company-info__description m-b-m m-t-l"
                data-testid="newsroomEditableDescription"
              >
                <editable-field-extended
                  ref="description"
                  :empty-text="$t('pages.newsroom_edit.add_description')"
                  :value="form.description"
                  :validator="$v.form.description"
                  :label="$t('pages.newsroom_edit.placeholder_description')"
                  :placeholder="$t('pages.newsroom_edit.placeholder_description')"
                  show-save-button
                  floating-label
                  control="EditableControlTextarea"
                  @input="patch({ description: $event }, 'description')"
                  @cancel="revertOldData"
                >
                  <multiline-content :value="form.description" class="break-words" />
                </editable-field-extended>
              </div>

              <div class="m-b-s">
                <strong>{{ $t('components.company_sidebar.contact_info') }}</strong>
              </div>

              <!-- CONTACT INFO -->
              <editable-field-extended
                ref="contact-info"
                :empty-text="$t('pages.newsroom_edit.add_contact_info')"
                :value="contactInfo"
                :validator="$v.form"
                hide-display-validation
                data-testid="newsroomEditableContactInfo"
                control="EditableControlContactInfo"
                @input="patch($event, 'contact-info')"
                @cancel="revertOldData"
              >
                <div :class="{ 'EditableField__placeholder': !form.contact_name }" class="company-info__name">
                  {{ form.contact_name || $t('pages.newsroom_edit.placeholder_contact_person_name') }}
                </div>

                <div :class="{ 'EditableField__placeholder': !form.contact_role }" class="company-info__role">
                  {{ form.contact_role || $t('pages.newsroom_edit.placeholder_contact_add_person_role') }}
                </div>

                <div :class="{ 'EditableField__placeholder': !form.contact_email }" class="company-info__email">
                  {{ form.contact_email || $t('pages.newsroom_edit.placeholder_contact_add_email') }}
                </div>

                <div :class="{ 'EditableField__placeholder': !form.contact_phone }" class="company-info__phone">
                  {{ form.contact_phone || $t('pages.newsroom_edit.placeholder_contact_add_phone_number') }}
                </div>
              </editable-field-extended>

              <br>
              <div><strong>{{ $t('components.company_sidebar.address') }}</strong></div>

              <!-- ADDRESS -->
              <editable-field-extended
                ref="address"
                :empty-text="$t('pages.newsroom_edit.add_company_address')"
                :value="form.address"
                :placeholder="$t('pages.newsroom_edit.placeholder_address')"
                show-save-button
                control="EditableControlTextarea"
                data-testid="newsroomEditableAddress"
                @input="patch({ address: $event }, 'address')"
                @cancel="revertOldData"
              >
                <multiline-content :value="form.address" />
              </editable-field-extended>

              <!-- WEBSITE -->
              <div class="m-t-m">
                <strong>{{ $t('forms.website') }}</strong>
              </div>
              <editable-field-extended
                ref="website"
                :empty-text="$t('pages.newsroom_edit.add_company_website')"
                :validator="$v.form.website"
                :value="form.website"
                placeholder="www.hypefactors.com"
                data-testid="newsroomEditableWebsite"
                class="m-b-m"
                @input="patch({ website: $event }, 'website')"
                @cancel="revertOldData"
              />

              <!-- MAIN COLOR -->
              <newsroom-edit-main-color
                ref="main_color"
                :value="form.main_color"
                class="m-b-m"
                data-testid="newsroomEditableMainColor"
                @input="patch({ main_color: $event }, 'main_color')"
              />

              <!-- CATEGORIES -->
              <newsroom-edit-categories
                ref="categories"
                :value="form.categories"
                :loading="isSaving"
                class="m-b-l"
                @input="patch({ categories: $event }, 'categories')"
              />

              <!-- COUNTRY AND LANGUAGE SELECT -->
              <newsroom-edit-localisation
                ref="localisation"
                :value="newsroomCountryAndLanguage"
                :validator="$v.form"
                :newsroom="newsroom"
                :is-loading="form.isLoading"
                class="m-b-l"
                @input="patch($event, 'localisation')"
                @published="handlePublishChange"
              />
            </div>
          </div>
        </div>

        <div class="column is-three-quarters company-page__content-wrap">
          <newsroom-edit-navigation :newsroom="newsroom" class="is-full-width" />

          <transition name="fade" mode="out-in">
            <router-view
              :form="form"
              :newsroom="newsroom"
              class="company-page__content"
              @update="patch"
            />
          </transition>
        </div>
      </div>
    </div>

    <!-- FIRST PUBLISH MODAL -->
    <NewsroomFirstTimePublishModal
      :visible.sync="isFirstTimePublish"
    />

    <!-- FIRST VISIT MODAL -->
    <el-dialog
      :visible.sync="isFirstVisit"
      :title="$t('pages.newsroom_edit.first_visit_modal.title')"
      class="FirstTimeVisitModal"
      @close="isFirstVisit = false"
    >
      <div class="p-v-s">
        {{ $t('pages.newsroom_edit.first_visit_modal.text') }}
      </div>
      <div class="p-v-s">
        {{ $t('pages.newsroom_edit.first_visit_modal.text_2') }}
      </div>
      <div slot="footer">
        <v-button
          class="is-primary"
          @click="isFirstVisit = false"
        >
          {{ $t('forms.get_started') }}
        </v-button>
      </div>
    </el-dialog>

    <!-- PICK MAIN COLOR MODAL -->
    <NewsroomPickMainColorModal
      :visible.sync="isMainColorPickerModalVisible"
      :color="form.main_color"
      :image-url="form.logo.original"
      @update:color="patch({ main_color: $event })"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import { createNewsroomForm } from '@/forms/NewsroomForm'
import { Newsroom } from '@hypefactors/shared/js/models/Newsroom'
import { NewsroomService } from '@hypefactors/shared/js/services/api/NewsroomService'

import { changePageTitle } from '@/shared/utils'

import NewsroomPickMainColorModal from '@hypefactors/shared/js/components/newsroom/NewsroomPickMainColorModal'
import NewsroomFirstTimePublishModal from '@hypefactors/shared/js/components/newsroom/NewsroomFirstTimePublishModal'

import NewsroomEditLogo from '@/components/newsroomEdit/NewsroomEditLogo'
import NewsroomEditCover from '@/components/newsroomEdit/NewsroomEditCover'
import EditableFieldExtended from '@/components/newsroomEdit/EditableFieldExtended'
import NewsroomEditMainColor from '@/components/newsroomEdit/NewsroomEditMainColor'
import NewsroomEditCategories from '@/components/newsroomEdit/NewsroomEditCategories'
import NewsroomEditNavigation from '@/components/newsroomEdit/NewsroomEditNavigation'
import NewsroomEditSocialLinks from '@/components/newsroomEdit/NewsroomEditSocialLinks'
import NewsroomEditLocalisation from '@/components/newsroomEdit/NewsroomEditLocalisation'
import NewsroomEditPreviewButton from '@/components/newsroomEdit/NewsroomEditPreviewButton'
import NewsroomEditGoToEmbedNewsroom from '@/components/newsroomEdit/NewsroomEditGoToEmbedNewsroom'
import AlternativeNewsroomPicker from '@/components/newsroom/AlternativeNewsroomPicker'
import MultilineContent from '@hypefactors/shared/js/components/core/MultilineContent'

export default {
  name: 'NewsroomEdit',

  components: {
    MultilineContent,
    AlternativeNewsroomPicker,
    NewsroomEditGoToEmbedNewsroom,
    NewsroomEditPreviewButton,
    EditableFieldExtended,
    NewsroomEditLogo,
    NewsroomEditCover,
    NewsroomEditLocalisation,
    NewsroomEditMainColor,
    NewsroomEditCategories,
    NewsroomEditNavigation,
    NewsroomEditSocialLinks,
    NewsroomPickMainColorModal,
    NewsroomFirstTimePublishModal
  },

  props: {
    newsroomSlug: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      isFirstVisit: false,
      isFirstTimePublish: false,
      isSaving: false,
      /** @type Newsroom */
      newsroom: null,
      /** @type NewsroomForm */
      form: createNewsroomForm(),
      formBackup: null,
      isMainColorPickerModalVisible: false
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  computed: {
    contactInfo () {
      return {
        contact_name: this.form.contact_name,
        contact_role: this.form.contact_role,
        contact_email: this.form.contact_email,
        contact_phone: this.form.contact_phone
      }
    },

    newsroomCountryAndLanguage () {
      return {
        country: this.form.country
      }
    }
  },

  async mounted () {
    await this.shouldFetchCategories()
    this.fetch()
  },

  beforeDestroy () {
    this.form.cancelToken && this.form.cancelToken.cancel()
  },

  async beforeRouteUpdate (to, from, next) {
    // if from and to is different and not equal to newsroom.edit
    if (to.name !== 'newsroom.edit' || (to.params.newsroomSlug === from.params.newsroomSlug)) {
      return next()
    }
    this.form.reset()
    await this.fetch(to.params.newsroomSlug)
    next()
  },

  methods: {
    ...mapActions(['shouldFetchCategories']),

    /**
     * Patches only parts of the Newsroom data
     * @param {Object} partial - only the updated data
     * @param {String} [ref] - ref id. Used to close {@see module:EditableField} fields
     * @return {Promise<*>}
     */
    patch (partial, ref) {
      this.formBackup = this.form.data()
      this.form.merge(partial)

      return this.saveSettings()
        .then(() => {
          // close the EditableField that triggered this action.
          if (ref && this.$refs[ref]) {
            this.$refs[ref].stopEditing()
            this.formBackup = this.form.data()
          }
        })
        .catch((error) => {
          // if error is true, its coming from the validation.
          if (error === true || this.$api.isCancelToken(error)) return
          // return the old value only if error is something else
          this.revertOldData()
          throw error
        })
    },

    revertOldData () {
      this.form.merge(this.formBackup)
    },

    async updateLogo (value) {
      let oldUrl = this.form.logo.original
      let newUrl = value.original
      this.askUserToPickMainColor(newUrl, oldUrl)
      await this.patch({ logo: value })
    },

    askUserToPickMainColor (newUrl, oldUrl) {
      if (newUrl && (newUrl !== oldUrl)) {
        this.isMainColorPickerModalVisible = true
      }
    },

    fetch (id) {
      id = id || this.newsroomSlug

      this.startLoad()

      return NewsroomService.fetchNewsroom(id, { params: { include: 'brand,categories,contacts,country,files' } })
        .then((response) => {
          this.stopLoad()

          this.newsroom = new Newsroom(response.data.data)
          this.isFirstVisit = (this.newsroom.updated_at === this.newsroom.created_at)

          this.form.mergeModel(this.newsroom)

          changePageTitle(this.newsroom.name)
        })
        .catch((error) => {
          this.stopLoadWithFail()
          this.$displayRequestError(error, this.$t('errors.general'), 5000, [404]) // skip reporting 404's
          this.$router.push({ name: 'newsroom.stories', params: { newsroomSlug: id } })
        })
    },

    saveSettings () {
      this.$v.form.$touch()

      if (this.$v.form.$error) {
        this.$notify.info(this.$t('validations.form_error'))

        return Promise.reject(this.$v.form.$error)
      }

      this.isSaving = true
      return this.form.update(this.newsroom.id)
        .then(newsroomResponse => {
          this.newsroom = new Newsroom(newsroomResponse.data)
          this.form.mergeModel(this.newsroom)

          // notify
          this.$notify.success({
            title: this.$t('general.success'),
            message: this.$t('success.newsroom_settings')
          })
        })
        .catch(error => {
          if (this.$api.isCancelToken(error)) throw error
          this.$displayRequestError(error, this.$t('errors.newsroom_settings'), 5000)

          throw error
        })
        .finally(() => {
          this.isSaving = false
        })
    },

    handlePublishChange (isPublished) {
      if (isPublished && !this.newsroom.stories_count) {
        this.isFirstTimePublish = true
      }
      this.newsroom.is_published = isPublished
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.company-page {
  padding-bottom: 2rem;

  &__logo-wrapper {
    margin-top: $margin;
    display: flex;
    justify-content: center;

    .CroppieWrapper {
      display: block;
    }
  }

  &__content-wrap {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
  }

  &__content {
    width: 100%;
  }

  &__name {
    .EditableField__field input {
      font-size: 2.4em;
      min-height: 1.5em !important;
      height: 1.5em;
    }
  }

  .content-wrapper {
    .company-page-block {
      border-bottom: $hf__border--base;
      margin-bottom: 1rem;

      &:last-child {
        border: none;
      }
    }
  }
}

@include tablet {
  .company-page {
    &__name {
      margin-top: 50px;
    }

    &__logo-wrapper {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 0;
    }

    .AlternativeNewsroomPicker {
      position: absolute;
      right: 0;
      top: $margin-large;
      @include rtl {
        left: 0;
        right: auto;
      }
    }

    .content-wrapper {
      padding: 2rem 0;
      flex: 1 1 auto;
    }
  }
}

.company-page-sidebar {
  .company-info {
    margin: 1.5rem 0 0;

    &__description {
      width: 100%;
    }

    h4 {
      margin: 0;
    }

    .website {
      color: inherit;
    }
  }
}

.NewsroomEdit {
  &__ControlEditableName input {
    font-size: 2.4rem;
    font-weight: 600;
    color: $hf__heading-color;
  }
}
</style>
