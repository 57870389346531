<template>
  <div class="EditableControl EditableControlContact">
    <form-field
      :validator="validator.contact_name"
      :attribute="$t('pages.newsroom_edit.placeholder_contact_person_name')"
    >
      <input
        ref="contact_name"
        v-model="value.contact_name"
        :placeholder="$t('pages.newsroom_edit.placeholder_contact_person_name')"
        class="input"
        type="text"
        name="contact_name"
      >
    </form-field>
    <form-field
      :validator="validator.contact_role"
      :attribute="$t('pages.newsroom_edit.placeholder_contact_person_role')"
    >
      <input
        v-model="value.contact_role"
        :placeholder="$t('pages.newsroom_edit.placeholder_contact_person_role')"
        class="input"
        type="text"
        name="contact_role"
      >
    </form-field>
    <form-field
      :validator="validator.contact_email"
      :attribute="$t('pages.newsroom_edit.placeholder_contact_email')"
    >
      <input
        v-model="value.contact_email"
        :placeholder="$t('pages.newsroom_edit.placeholder_contact_email')"
        class="input"
        type="text"
        name="contact_email"
      >
    </form-field>
    <form-field
      :validator="validator.contact_phone"
      :attribute="$t('pages.newsroom_edit.placeholder_contact_phone_number')"
    >
      <input
        v-model="value.contact_phone"
        :placeholder="$t('pages.newsroom_edit.placeholder_contact_phone_number')"
        class="input"
        type="text"
        name="contact_phone"
      >
    </form-field>

    <div class="has-text-right">
      <v-button
        class="button is-plain is-dark"
        data-testid="cancelEditableContactInfo"
        @click="$emit('cancel')"
      >
        {{ $t('forms.cancel') }}
      </v-button>
      <v-button
        class="is-primary"
        data-testid="saveEditableContactInfo"
        @click="save"
      >
        {{ $t('pages.newsroom_edit.save_contact_info') }}
      </v-button>
    </div>
  </div>
</template>

<script>
import EditableControlPrototype from '@hypefactors/shared/js/components/forms/controls/EditableControlPrototype'

export default {
  name: 'EditableControlContactInfo',

  mixins: [EditableControlPrototype],

  props: {
    value: {
      type: Object,
      default: null
    }
  },

  methods: {
    /**
     * Overrides the default focus method because we have more than one inputs
     */
    focus () {
      this.$refs.contact_name.focus()
    }
  }
}
</script>
