<template>
  <el-popover
    v-click-away="handleClickAway"
    :value="visible"
    :visible-arrow="false"
    trigger="manual"
    placement="bottom-start"
    class="NewsroomEditSocialLinksDropdown"
  >
    <newsroom-edit-social-links-group
      ref="handlesGroup"
      v-bind="{ handles, type, newsroomId }"
      v-on="$listeners"
      @cancel="close"
    />
    <template slot="reference">
      <v-button
        :class="[handles.length ? `is-${type}`: 'is-grey-light']"
        class="is-round"
        data-testid="NewsroomEditSocialLinksGroupTrigger"
        @click.prevent="toggleEditing"
      >
        <span class="icon">
          <v-icon :type="type" />
        </span>
      </v-button>
    </template>
  </el-popover>
</template>

<script>
import NewsroomEditSocialLinksGroup from './NewsroomEditSocialLinksGroup'

/**
 * @module NewsroomEditSocialLinksDropdown
 */
export default {
  name: 'NewsroomEditSocialLinksDropdown',
  components: { NewsroomEditSocialLinksGroup },
  props: {
    type: {
      type: String,
      default: ''
    },
    handles: {
      type: Array,
      required: true
    },
    newsroomId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    async handleClickAway () {
      if (!this.visible) return
      await this.$refs.handlesGroup.saveAll()
      this.visible = false
    },
    /**
     * When clicking the social icon toggles the dropdown on/off
     */
    async toggleEditing () {
      if (this.visible) {
        await this.$refs.handlesGroup.saveAll()
      } else {
        this.visible = true
      }
    },
    close () {
      this.visible = false
    }
  }
}
</script>
