<template>
  <el-dialog
    :visible.sync="isVisible"
    :title="$t('components.story_newsroom_edit.newsroom_first_time_publish_modal.title')"
    class="NewsroomFirstTImePublishModal"
    data-testid="NewsroomFirstTimePublishModal"
  >
    <div class="m-b-1">
      {{ $t('components.story_newsroom_edit.newsroom_first_time_publish_modal.description_1') }}
    </div>
    <div slot="footer" class="buttons is-right">
      <button class="button is-button is-lack" @click="isVisible = false">
        {{ $t('forms.close') }}
      </button>
      <button class="button is-primary" @click.prevent="goToStoriesUrl">
        {{ $t('forms.take_me_there') }}
      </button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (value) {
        this.$emit('update:visible', value)
      }
    }
  },
  methods: {
    goToStoriesUrl () {
      this.isVisible = false
      this.$redirectTo(this.$getUrl('stories', 'hypefactors'), 0, 'hf_stories')
    }
  }
}
</script>
