<template>
  <div
    class="NewsroomEditLogo"
    data-testid="NewsroomEditLogo"
  >
    <croppie-wrapper
      ref="company_logo"
      v-model="currentValue"
      :min-width="100"
      :min-height="100"
      :settings="croppieSettings"
      :brand-id="newsroom.brand.id"
      :crop-options="croppieSettings.cropOptions"
      :enforce-boundary="false"
      is-circle
      text-size="5"
      identifier="profile"
      entity="NewsroomsLogo"
      @removed="triggerInput"
      @save="save"
    >
      <span>{{ $t('components.story_newsroom_edit.newsroom_edit_logo.add') }}</span>
    </croppie-wrapper>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import { croppieDataFactory, croppieDefaultSettings } from '@hypefactors/shared/js/factories/croppie'

export default {
  name: 'NewsroomEditLogo',

  props: {
    value: {
      type: Object,
      required: true
    },
    newsroom: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    currentValue: croppieDataFactory(),
    croppieSettings: croppieDefaultSettings(160, 160)
  }),

  watch: {
    value: {
      immediate: true,
      handler: 'syncValue'
    }
  },

  methods: {
    syncValue () {
      this.currentValue = _cloneDeep(this.value)
    },

    async save () {
      try {
        await this.$refs.company_logo.uploadImage()
        this.triggerInput()
      } catch (err) {
        if (this.$api.isCancelToken(err)) return
        this.$displayRequestError(err, this.$t('errors.could_not_upload_logo'))
      }
    },

    triggerInput () {
      this.$emit('input', this.currentValue)
    },

    feedNewImageUrl (url) {
      return this.$refs.company_logo.feedNewImageUrl(url)
    }
  }
}
</script>
