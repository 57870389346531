<template>
  <el-dialog
    :visible.sync="isVisible"
    :title="$t('components.story_newsroom_edit.newsroom_pick_main_color_modal.title')"
    class="NewsroomPickMainColorModal"
    data-testid="NewsroomPickMainColorModal"
    width="60%"
  >
    <h5 class="title is-5">
      {{ $t('components.story_newsroom_edit.newsroom_pick_main_color_modal.description') }}
    </h5>

    <div
      v-loading="isExtracting"
      class="columns is-multiline is-mobile is-aligned-bottom"
    >
      <div class="column is-4-mobile is-2-tablet">
        <h6 class="title is-6">
          {{ $t('components.story_newsroom_edit.newsroom_pick_main_color_modal.current_color') }}
        </h6>

        <div
          class="current-color color"
        >
          <div class="color__color is-round has-no-overflow">
            <div class="image is-1by1">
              <div
                :style="{backgroundColor: chosenColor}"
                class="floated"
              />
            </div>
          </div>

          <div class="color__title has-text-centered">
            {{ chosenColor }}
          </div>
        </div>
      </div>

      <div class="column">
        <div class="available-colors columns is-mobile is-multiline">
          <div
            v-for="currentColor in colors"
            :key="currentColor"
            class="column is-4-mobile is-2-tablet"
          >
            <div
              :class="{ 'is-active': (tempColor === currentColor) }"
              class="color is-cursor-pointer"
              data-testid="pickAvailableColor"
              @click="pickColor(currentColor)"
              @dblclick="pickAndClose(currentColor)"
            >
              <div class="color__color is-round has-no-overflow">
                <div class="image is-1by1">
                  <div
                    :style="{backgroundColor: currentColor}"
                    class="floated"
                  />
                </div>
              </div>

              <div class="color__title has-text-centered">
                {{ currentColor }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <button
        class="button"
        @click="isVisible = false"
      >
        {{ $t('forms.cancel') }}
      </button>

      <button
        :disabled="disabled"
        class="button is-primary"
        @click="closeModal"
      >
        {{ $t('general.confirm') }}
      </button>
    </div>
  </el-dialog>
</template>

<script>
import { generateComputedSetterWithEmit, generateUniqueWatcher } from '../../utils/componentUtilities'
import { ColorPaletteService } from '@hypefactors/hypenews/src/services/ColorPaletteService'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ''
    },
    imageUrl: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      tempColor: '',
      colors: [],
      isExtracting: false
    }
  },

  computed: {
    isVisible: generateComputedSetterWithEmit('visible', 'update:visible'),

    chosenColor: {
      get () {
        return this.color
      },
      set (value) {
        this.$emit('update:color', value)
      }
    },

    disabled () {
      if (this.colors.length === 0 || !this.tempColor) return true
      return !this.colors.includes(this.tempColor)
    }
  },

  watch: {
    imageUrl: generateUniqueWatcher('extractColorFromLogoImage')
  },

  methods: {
    pickColor (color) {
      this.tempColor = color
    },
    pickAndClose (color) {
      this.pickColor(color)
      this.closeModal()
    },
    async extractColorFromLogoImage (imageUrl) {
      this.colors = []
      if (!imageUrl) return
      this.isExtracting = true
      try {
        this.colors = await ColorPaletteService.getPalette(imageUrl)
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isExtracting = false
      }
    },
    closeModal () {
      this.chosenColor = this.tempColor
      this.tempColor = ''
      this.colors = []
      this.isVisible = false
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.available-colors {
  .color__color {
    &:hover {
      transform: scale(1.05);
    }
  }
}

.color {
  &__color {
    @include transit();
    @include shadow-1();
  }

  &__title {
    color: $hf__text-muted;
    font-weight: $hf__font-weight-bold;
    text-transform: uppercase;
  }

  &.is-active {
    .color__color {
      border: 3px solid $grey-dark;
    }
  }
}
</style>
