<template>
  <newsroom-navigation-base :newsroom="newsroom">
    <template slot-scope="{ hasSocialFeed, hasAssets, hasVideo }">
      <newsroom-navigation-item
        :to="{ name: 'newsroom.edit', params: { newsroomSlug: newsroom.slug } }"
        :text="$t('components.company_navigation.company_news')"
        icon="file-text-o"
      />
      <newsroom-navigation-item
        :title="newsroom.name"
        :class="{ 'NewsroomNavItem--empty': !hasSocialFeed && !hasVideo }"
        :text="$t('components.company_navigation.social')"
        :to="{ name: 'newsroom.edit.about', params: { newsroomId: newsroom.id, newsroomSlug: newsroom.slug } }"
        icon="user-circle-o"
      />
      <newsroom-navigation-item
        :class="{ 'NewsroomNavItem--empty': !hasAssets }"
        :to="{ name: 'newsroom.edit.assets', params: { newsroomId: newsroom.id, newsroomSlug: newsroom.slug } }"
        :text="$t('components.company_navigation.company_assets')"
        icon="files"
      />
    </template>
  </newsroom-navigation-base>
</template>

<script>
import NewsroomNavigationItem from '../newsroom/NewsroomNavigationItem'
import NewsroomNavigationBase from '../newsroom/NewsroomNavigationBase'

export default {
  name: 'NewsroomNavigation',
  components: { NewsroomNavigationBase, NewsroomNavigationItem },
  props: {
    newsroom: {
      type: Object,
      required: true
    }
  }
}
</script>
